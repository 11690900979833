<template>
  <div class="amis">
    <el-switch v-model="preview" active-text="预览" />
    <div ref="renderBox" class="content" />
  </div>
</template>

<script>
import { isAuth } from '@/utils';
import { Editor } from 'amis-editor';
import 'amis-editor/dist/style.css';
import 'amis/lib/helper.css';
import ReactDOM from 'react-dom';

export default {
  data() {
    return {
      preview: false,
    };
  },
  watch: {
    preview() {
      ReactDOM.unmountComponentAtNode(this.$refs.renderBox);
      this.render();
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      const schemaKey = 'amis-editor-schema';
      const schemaJson = localStorage.getItem(schemaKey);
      const editor = new Editor({
        value: schemaJson ? JSON.parse(schemaJson) : {},
        onChange: (schema) =>
          localStorage.setItem(schemaKey, JSON.stringify(schema)),
        autoFocus: true,
        preview: this.preview,
        data: { isAuth },
      });
      ReactDOM.render(editor.render(), this.$refs.renderBox);
    },
  },
};
</script>

<style lang="scss" scoped>
.amis {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .el-switch {
    margin: 8px;
  }

  .content {
    flex: 1;
  }

  ::v-deep .ae-Editor {
    height: 100%;
  }
}
</style>
